export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      {/* If you want to add image again then search the about--section on app.css and make display grid and uncomment all styles */}
      {/* <div className="about--section--img">
        <img src="./img/Anoop_old.png" alt="About Me" />
      </div> */}
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          {/* <p className="section--title">About Me</p> */}
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
          I'm Anoop Chandra, an MCA (Master of Computer Applications) student specializing in Data Science at LPU, 
          Expected to complete MCA program in 2024. Currently enrolled in the PwSkill Data Science course.       </p>
          <p className="hero--section-description">
          I possess expertise in Machine Learning, Web scraping, Deep learning, NLP, Generative AI and Computer vision. 
Eager to apply my skills to real-world challenges, I'm a quick learner, team player, and always keen on skill enhancement. 
My versatile skill set includes crafting predictive models, automating data extraction, delving into neural networks, exploring computer vision, and unraveling the power of language through NLP. 
   
          </p>
        </div>
      </div>
    </section>
  );
}
